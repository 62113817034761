<template>
<div>
<bar ref="bar" close="true" step="Escuchar" :help="instructions"/>

<v-content>
  	<message/>
	<v-container grid-list-md>

	  <v-btn
	    color="primary"
	    block
	    v-show="!$store.state.fromStart"
	    @click="init()"
	  >
	    CONTINUAR LA PRUEBA
	  </v-btn>

	  <v-layout column wrap align-center justify-center>

      <audio-basic ref="audioBasic"/>
      <captions-viewer ref="captionsViewer" class="mt-1"/>
	    <dictionary ref="dictionary" :random="false"/>

	  </v-layout>
	</v-container>
</v-content>

</div>
</template>

<script>

import bar from '../components/appComponents/Bar.vue'
import message from '../components/appComponents/Message.vue'
import captionsViewer from '../components/studyUtils/CaptionsViewer.vue'
import dictionary from '../components/studyUtils/Dictionary.vue'
import audioBasic from '../components/studyUtils/AudioBasic.vue'

export default {
  name: 'study-basic',
  data() {
    return {
      ended: false,
      initEvaluation: 0,
      finishEvaluation: 0,
      calculatedCaptions: 0,
      resource: '',
      instructions: {
        title : 'Escuchar en Modo Básico',
        text : 'Ahora sólo tienes que escuchar y leer.',
        titleSecondary : 'El modo básico',
        textSecondary : 'En el modo básico realizarás el ejercicio completo de un sólo verso, cuando lo completes pasarás al verso siguiente. De esta manera irás aprendiendo los versos del poema uno a uno. No te preocupes si no aciertas el verso a la primera, las palabras acertadas se guardarán para que lo sigas intentando.',
        key : 'instructionsBasicStudy'
      }
    }
  },
  components: {
    message,
    bar,
    'captions-viewer':captionsViewer,
    dictionary,
    'audio-basic' : audioBasic
  },
  mounted(){

    this.calculatedCaptions = [this.$store.state.step.captions[this.$store.state.step.status - 1]]
    this.initEvaluation = this.calculatedCaptions[0].init
    this.finishEvaluation = this.calculatedCaptions[0].finish
    this.resource = 'step/'+this.$store.state.step.id+'/audio'

    this.$store.state.messageView = false
    if(this.$store.state.fromStart){
      this.init()
    }
  },
  methods: {
    init: function(){
		
		  this.initAudio = this.$refs.audioBasic.init(this.initEvaluation,this.finishEvaluation,this.$store.state.step.status,this.resource)

		  let that = this
	    this.$root.$on('onPlayAudio',function(){
	    	that.$refs.captionsViewer.initCaptions(that.calculatedCaptions,that.initAudio)
	      that.$refs.dictionary.initUnknownWords(that.calculatedCaptions)
	    })
      this.$root.$on('onProgressChronometer',function(data){
        that.$refs.bar.setProgress(data.seconds,data.duration)
      })
	    this.$root.$on('onFinishAudio',function(){
        that.$store.state.step.learnWords = {
          redirect : 'play-basic',
          order: true
        }
        that.$store.state.unknownWords = []
        let ids = []
        let idsTrans = []
        const captions = that.$store.state.step.captions[ that.$store.state.step.status - 1 ]
        for (let i in captions.text){
          if (ids.indexOf(captions.text[i].text) === -1 && idsTrans.indexOf(captions.text[i].trans) === -1){
            that.$store.state.unknownWords.unshift( { 
              text : captions.text[i].text, 
              idText: captions.text[i].id,
              trans : captions.text[i].trans, 
              idTrans: captions.text[i].id,
              outlineText : false , 
              outlineTrans: false, 
              id: captions.text[i].id,
              uniqueId: captions.text[i].id
            } );
            ids.push(captions.text[i].text)
            idsTrans.push(captions.text[i].trans)
            that.$store.state.idsUnknownWords.push(captions.text[i].text);
          }
        }
        
        if (that.$store.state.step.starting) {
          that.$store.state.step.starting = false
          that.$store.state.currentRoute = '/learn-words'
        } else {
          that.$store.state.fromStart = true
          that.$store.state.step.redirect = 'play-basic'
          that.$store.state.step.message = 'Ahora escribe lo que escuchas'
          that.$store.state.currentRoute = '/instructions'
        }
        that.$router.push(that.$store.state.currentRoute)
	    })
	    
    }
  },
  beforeDestroy: function(){
  	this.$root.$off('onPlayAudio')
  	this.$root.$off('onFinishAudio')
    this.$root.$off('onProgressChronometer')
  }
}
</script>

<style>

</style>
